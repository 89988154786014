import axios from 'axios';
import { Failed_Connection_Error } from './constants';

const requestInterceptor = async (config: any) => {
  const token = sessionStorage.getItem('jwtToken');
  const username = sessionStorage.getItem('user');

  if (token && username) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const responseInterceptor = async (error: {
  response: { status: any };
  message: any;
}) => {
  if (error && !error.response) {
    //  Netwrok error:  Adding specific condition for network or VPN disconnect
    if (error.message === 'Network Error') {
      const errorObj = {
        response: {
          data: { error: { errorMessage: Failed_Connection_Error } },
        },
      };
      return Promise.reject(errorObj);
    }
  }

  if (error.response) {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      window.location.href = '/403';
    } else {
      console.error('API Error:', error.response);
    }
  } else {
    console.error('Request Error:', error.message);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(null, responseInterceptor);
