export const azure_llm_models_options = [
  ['GPT- 4 TURBO 128K - $$', 'gpt-4-turbo'],
  ['GPT-4o 128K', 'gpt-4o'],
];

export const gcp_llm_models_options = [
  ['GEMINI- PRO 32K - $$$', 'gemini-pro'],
  ['TEXT- BISON 32K - $$', 'text-bison-32k'],
  ['TEXT- BISON 8K - $', 'text-bison@001'],
];

export const private_llm_models_options = [
  ['MISTRAL- 7B', 'mistral:7b-instruct'],
  ['LLAMA 2- 7B', 'llama2:7b-chat'],
  ['Medicaid Fed Ft LLM', 'llama2:7b-chat-ft-medicaid-fed-20240201'],
];

export const aws_llm_models_options = [
  ['CLAUDE V2- 1 200K- $$', 'anthropic.claude-v2:1'],
  ['TITAN V1 TEXT 8K- $', 'amazon.titan-text-express-v1'],
];

export const model_recommended = 'recommended';
export const gcp_recommended_model = 'gemini-1.5-pro-preview-0409';
export const aws_recommended_model =
  'anthropic.claude-3-5-sonnet-20240620-v1:0';
export const azure_recommended_model = 'gpt-4o';
//Feedback Component Contants
export const ENTER_FEEDBACK_PLACEHOLDER = 'Please enter feedback...';
export const FEEDBACK_THANK_YOU_MSG = 'Thank you for your feedback!';
export const WAS_THIS_HELPFUL = 'Was this helpful?';
export const FEEDBACK_ERROR =
  'Sorry we encountered some issue in sending your feedback!';
export const SUBMIT = 'Submit';
export const THUMBS_UP_ICON = '👍';
export const THUMBS_DOWN_ICON = '👎';

//CaseCopiotConstants
export const UPLOAD_SUCCESS = 'Upload Successful';
export const OPTION_SELECT = 'Choose an option';
export const DATA_FILE = 'Data File';
export const FORMATS_SUPPORTED = 'Formats supported';
export const UPLOAD = 'Uploading';
export const DICTIONARY_FILE = 'Dictionary File';
export const QUESTION = 'Enter your question';
export const SNAP_MESSAGE = 'Something went wrong. Please try again';
export const ERROR_MESSAGE = 'Oh snap! You got an error!';
export const ADVANCED_ERROR_MESSAGE =
  ' Something went wrong with your Advanced settings. Please make sure required fields like query params are not blank.';
export const REFRESHING = 'Refreshing...';
export const FILE_TYPE_ERROR =
  'File type is not supported, please upload file with';

//InstanceGeneratorConstants
export const KNOWLEDGE_IDS = 'Knowledge IDs';

//LlmFineTuneDatasetGeneratorConstants:
export const CREATE_DATASET = 'Create a dataset from document';
export const UPLOAD_DATASET = 'Upload dataset file (CSV/XLSX) to upsample';

// Application constants:
export const USER_STORY_GENERATOR = 'User Story Generator';
export const SALES_ENGINE = 'Sales Engine';
export const MEETING_SUMMARIZER = 'Meeting Summarizer';
export const STATUS_REPORT_GENERATOR = 'Status Report Generator';
export const TEST_SCRIPT_GENERATOR = 'Test Case Generator';
export const SCAN_TO_SUMMIFY = 'Scan to Summify Engine';
export const HELP_DESK_ENGINE = 'Help Desk Engine';
export const NOTICE_ENGINE = 'Notice Explanation Engine';
export const NOTICE_ANALYSIS_ENGINE = 'Notice Analysis Engine';
export const CHANGE_COMMUNICATOR = 'Change Communicator';
export const CALL_CENTER = 'Call Center';
export const DOC_INSIGHT = 'Document Insight';
export const DEVELOPER_HELP_ENGINE = 'Developer Help Engine';
export const POLICY_ENGINE = 'Policy Engine';
export const HELP_ENGINE = 'Help Engine';
export const CODE_DOCUMENTATION = 'Code documentation';
export const METADATA_GENERATOR = 'Document Metadata Generator';
export const CASE_COPILOT_GENERATOR = 'Case Copilot Generator';
export const VISION_QA_ENGINE = 'Vision QA Engine';
export const VISION_VIDEO_ANALYTICS = 'Vision Video Analytics';
export const TEST_CASE_GENERATOR_2 = 'Test Case Generator 2.0';
export const TEST_CASE_GENERATION = 'Test Case Generation';
//VisionqaFeedback constants:
export const Feed_Back_Message = 'Thank you for the feedback!';

//DocumentOCR constants:
export const File_Format = '.pdf';
export const File_Formats = '.pdf, .jpeg, .jpg, .png';

//code to design constants
export const Code2Design = 'Code To Design';
export const Code_To_Design = 'Code to Design';
export const Code_To_Spec = 'Code to Spec';
export const VISUAL_BASIC = 'VISUAL_BASIC';
export const COBOL = 'COBOL';
export const OMNISCRIPT = 'OMNISCRIPT';

export const CODE_QNA = 'Code QnA';
export const CODE_SEARCH = 'Code Search';

//tab title
export const UPLOAD_TAB = 'Upload';
export const DOWNLOAD_DOCUMENTATION = 'Download Documentation';

//llm models
export const GPT_4_TURBO = 'gpt-4-turbo';
export const GPT_4O = 'gpt-4o';
export const GPT4_32K = 'gpt4-32k';
export const GPT4_8K = 'gpt4-8k';
export const GPT35_16K = 'gpt35-16k';
export const GPT35_4K = 'gpt35-4k';
export const GEMINI_PRO = 'gemini-pro';
export const GEMINI_1_5_PRO = 'gemini-1.5-pro-001';
export const TEXT_BISON_32K = 'text-bison-32k';
export const TEXT_BISON_001 = 'text-bison@001';
export const MISTRAL_7B = 'mistral:7b-instruct';
export const LLAMA2_7B = 'llama2:7b-chat';
export const LLAMA2_7B_CHAT_FT_MEDICAID_FED_20240201 =
  'llama2:7b-chat-ft-medicaid-fed-20240201';
export const ANTHROPIC_CLAUDE_V2_1 = 'anthropic.claude-v2:1';
export const AMAZON_TITAN_TEXT_EXPRESS_V1 = 'amazon.titan-text-express-v1';

//llm guard Scanner types
export const AdvancedAnonymize = 'AdvancedAnonymize';
export const Anonymize = 'Anonymize';
export const BanCode = 'BanCode';
export const BanCompetitors = 'BanCompetitors';
export const BanSubstrings = 'BanSubstrings';
export const BanTopics = 'BanTopics';
export const Code = 'Code';
export const Gibberish = 'Gibberish';
export const InvisibleText = 'InvisibleText';
export const Language = 'Language';
export const PromptInjection = 'PromptInjection';
export const Regex = 'Regex';
export const TokenLimit = 'TokenLimit';
export const Secrets = 'Secrets';
export const Sentiment = 'Sentiment';
export const Toxicity = 'Toxicity';
export const RegexLabel = 'Select regex patterns to Anonymize';
export const DRIVER_LICENCE_NUMBER = `DRIVER'S_LICENCE_NUMBER`;
export const DRIVERS_LICENCE_NUMBER = 'DRIVERS_LICENCE_NUMBER';

//Current User Role
export const CONTRIBUTOR = 'contributor';
export const ADMIN = 'admin';
export const SUPERADMIN = 'superadmin';

//Usage Tracking
export const AllProjects = 'all_Projects';

//Select instance knowledge id Status
export const Pending_Status = 'Pending';
export const Completed_Status = 'Completed';
export const Failed_Status = 'Failed';
export const InProgress_Status = 'In Progress';
export const Pending_Message =
  'Your instance is being set up. It may take a short while, depending on documents uploaded.';
export const Completed_Message = 'All set! Your instance is now ready to use.';
export const Failed_Message =
  'On Snap! Your instance could not be configured successfully.';
export const Failed_Message_Support =
  'Please setup the instance again or contact GenAIe team for assistance.';
export const Failed_Message_Support_one =
  'Please setup the instance again or provide error ID: ';
export const Failed_Message_Support_two =
  ' when contacting GenAIe team for assistance.';

export const Failed_Connection_Error =
  'You seem to be offline. Please check your network connection and try again.';

//Fine Tune Dataset Generator
export const pending = 'Pending';
export const completed = 'Completed';
export const failed = 'Failed';
export const upload = 'Upload';
export const collections = 'collections';
export const light = 'light';
export const dark = 'dark';
export const primary = 'primary';
export const white = 'white';
export const generateTextVariations = 'Generate Text Variations';
export const dalleimage = 'DALL-E Image Generate';
export const generateqa = 'Generate QA';
export const createsimilarquestionqa = 'Create Similar Questions QA';

//For instance generator
export const testSteps = 'Test Steps';
export const title = 'Title';
export const expectedOutCome = 'Expected Outcome';
export const userNoteOutputColum1 =
  'Fixed Columns: Title, Test Steps, and Expected Result cannot be edited.';
export const userNoteOutputColum2 = `Add Columns: Click the '+' icon to add new columns. They will appear in the order added.`;
export const userNoteOutputColum3 =
  'Reorder Columns:Drag and drop new columns to rearrange them. The final order will be preserved in the final response.';
export const fileUploadKnowledegenote1 =
  'Note: 1. Formats supported : pdf, doc, docx, txt, xlsx & csv.';
export const fileUploadKnowledegenote2 =
  '2. For accurate results, please upload documents with tables in PDF format only, with a maximum length of 25 pages. Uploading documents in other formats (e.g., DOC, DOCX, TXT) may result in incorrect recognition of table content';
export const customOutputColumnNote1 =
  '1. Non-Editable Columns: The Title, Test Steps, and Expected Result columns cannot be edited.';
export const customOutputColumnNote2 =
  '2. File Upload Experience: These non-editable columns apply only to the "File Upload" experience, meaning test cases must follow the pre-defined format.';
export const customOutputColumnNote3 =
  '3. Chatbot Experience: The "System Template" format will be used for the "Chatbot" experience. If template instructions differ, the chatbot will follow the template, while file uploads will use the specified columns.';
export const KnowledgeNameError =
  'Knowledge Name is a mandatory field. Please enter the required Knowledge Name to proceed further.';
export const TokenLimitError1 = 'Token limit for ';
export const TokenLimitError2 =
  ' exceeded. To fix this issue, please consider changing the LLM model or reducing the knowledge IDs used in the above template.';

//For vector DB :
export const WEAVIATE = 'weaviate';
export const PGVECTOR = 'pgvector';
export const OPENSEARCH_SERVERLESS = 'opensearch_serverless';

//For Advance Settings:
export const NORMAL = 'Normal';

export const hexColors = [
  '#2ECC71', // Lime green
  '#F1C40F', // Amber
  '#7F8C8D', // Dusty blue
  '#7C4DFF', // Pastel purple
  '#FF69B4', // Pastel pink
  '#34A85A', // Minty fresh
  '#FFC107', // Vibrant orange
  '#8E24AA', // Rich plum
  '#4CAF50', // Lime green
  '#3F51B5', // Deep blue
  '#FF9800', // Warm orange
  '#9C27B0', // Bright magenta
  '#03A9F4', // Sky blue
  '#FF4081', // Fuchsia
  '#FFC0CB', // Pastel pink
  '#F7DC6F', // Sunshine yellow
  '#2196F3', // Cobalt blue
  '#FFA07A', // Coral
  '#8BC34A', // Fresh mint
  '#9E9D24', // Olive green
  '#00BFA5', // Teal
  '#FFC400', // Golden yellow
  '#7B1FA2', // Rich berry
  '#1A237E', // Navy blue
  '#FF99CC', // Pastel peach
  '#4DB6AC', // Seafoam green
  '#FFA57D', // Burnt orange
  '#6A1B9A', // Deep plum
  '#00E676', // Bright green
  '#6495ED', // Cornflower blue
  '#DC143C', // Crimson
];

//File formats:
export const PDF = '.pdf';
export const DOC = '.doc';
export const DOCX = '.docx';
export const TXT = '.txt';
export const MD = '.md';
export const XLSXFile = '.xlsx';
export const CSV = '.csv';
export const PNG = '.png';
export const JPEG = '.jpeg';
export const JPG = '.jpg';
export const MP3 = '.mp3';
export const MP4 = '.mp4';
export const M4A = '.m4a';
export const WAV = '.wav';
export const MOV = '.mov';
export const ZIP = '.zip';
export const PPT = '.ppt';
export const PPTX = '.pptx';
export const FRM = '.frm';
export const PCO = '.pco';
export const COBOLFile = '.cobol';
export const CTL = '.ctl';
export const CLS = '.cls';

// Usage Dashboard
export const ActivitiesPieChart = 'ActivitiesPieChart';
export const CostPieChart = 'CostPieChart';
export const Engine = 'Engine';
export const UsageDashboard = 'UsageDashboard';
export const Timelines = 'Timelines';
export const Week = 'Week';
export const Day = 'Day';
export const Month = 'Month';
export const Year = 'Year';
export const activity_cost = 'activity_cost';
export const activity_count = 'activity_count';
export const Project = 'Project';
export const Cost = 'Cost';
export const Activities = 'Activities';
export const User = 'User';
export const email = 'email';
export const projectname = 'projectname';
export const applicationName = 'applicationName';
export const julyDate = '2024-07-01';

//cloudProviders
export const AZURE_CLOUD = 'azure';
export const GCP_CLOUD = 'gcp';
export const AWS_CLOUD = 'aws';
export const CFG_AI_CLOUD = 'cfg_ai';

//For Document Metadata Generator

export const customJson = 'Custom JSON';
export const customPrompt = 'Custom Prompt';
export const dictionaryMapper = 'Dictionary Mapper';
