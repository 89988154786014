import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const authenticateUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/authentication/sso-login`
    );
    const redirect_url = response.data?.redirect_url;

    if (redirect_url) {
      window.location.href = redirect_url;
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const setAuthToken = (token: any) => {
  sessionStorage.setItem('jwtToken', token);
  const decoded: { sub?: string; exp?: number; role?: string } =
    jwtDecode(token);
  const userName = decoded?.sub;
  const exp = decoded?.exp;
  const role = decoded?.role;
  sessionStorage.setItem('role', JSON.stringify(role));
  sessionStorage.setItem('user', JSON.stringify(userName));
  sessionStorage.setItem('expiry', JSON.stringify(exp));
};

export const getAuthToken = () => {
  return sessionStorage.getItem('jwtToken');
};

export const removeAuthToken = () => {
  sessionStorage.removeItem('jwtToken');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('expiry');
};

export const isTokenExpired = () => {
  const expiry = sessionStorage.getItem('expiry');
  const currentTime = Date.now();
  const expiredTime = expiry ? parseInt(expiry, 10) * 1000 : 0;

  return currentTime > expiredTime;
};

export const updateAuthToken = async () => {
  try {
    const payload = {
      access_token: sessionStorage.getItem('jwtToken'),
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/authentication/token-refresh`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const token = response.data?.access_token;
    setAuthToken(token);
  } catch (error) {
    return false;
  }
};
