import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserContextProvider from './context/UserContextProvider';
import { PrivateRoute } from './privateRoute';
import Loader from './components/Loader';
const Home = lazy(() => import('./pages/Home'));
const Project = lazy(() => import('./pages/Project'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const Application = lazy(() => import('./pages/Application'));
const ActivityHistoryTable = lazy(() => import('./pages/ActivityHistoryTable'));
const Copilot = lazy(() => import('./pages/Copilot'));
const CopilotNoUIV2 = lazy(() => import('./pages/CopilotNoUIV2'));
const TotalCost = lazy(() => import('./pages/TotalCost'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: 'project/:projectId',
        element: (
          <Suspense fallback={<Loader />}>
            <Project />
          </Suspense>
        ),
      },
      {
        path: 'project/:projectId/:appUrl',
        element: (
          <Suspense fallback={<Loader />}>
            <Application />
          </Suspense>
        ),
      },
      {
        path: 'project/:projectId/activity-history',
        element: (
          <Suspense fallback={<Loader />}>
            <ActivityHistoryTable />
          </Suspense>
        ),
      },
      {
        path: 'project/:projectId/co-pilot',
        element: (
          <Suspense fallback={<Loader />}>
            <Copilot />
          </Suspense>
        ),
      },
      {
        path: 'project/:projectId/copilotNoUI',
        element: (
          <Suspense fallback={<Loader />}>
            <CopilotNoUIV2 />
          </Suspense>
        ),
      },
      {
        path: 'project/track-usage',
        element: (
          <Suspense fallback={<Loader />}>
            <TotalCost />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '*',
    element: (
      <Routes>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <ErrorPage error={404} />
            </Suspense>
          }
        />
        <Route
          path="/403"
          element={
            <Suspense fallback={<Loader />}>
              <ErrorPage error={403} />
            </Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<Loader />}>
              <LogoutPage />
            </Suspense>
          }
        />
      </Routes>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
